<script setup>

import SecondaryButton from "@/Components/SecondaryButton.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";

const pageProps = getCurrentInstance().appContext.config.globalProperties.$page;
const handleAddMyStoryClick = () => {
    if (pageProps.props.auth.user) {
        router.get(route('add.timepiece', { 'profile_id': pageProps.props.auth.user?.main_profile?.id }));
    } else {
        router.visit(route('register'));
    }
}
const handleAddLovedOneStoryClick = () => {
    if (pageProps.props.auth.user) {
        router.visit(route('immortalize.another'));
    } else {
        router.visit(route('register'));
    }
}
</script>

<template>
    <div class="flex gap-12 flex-col sm:flex-row my-16 justify-between">
        <div class="flex-1 flex flex-col justify-center items-start max-w-xl">
            <div class="text-teal-500 text-base font-semibold">{{ $t('Sign up now') }}</div>
            <div class="text-teal-900 text-2xl sm:text-4xl md:text-5xl font-medium font-lora mt-4 md:leading-[60px]">{{ $t('Immortalize yourself or a loved one') }}</div>
            <div class="text-slate-600 text-base sm:text-lg md:text-xl font-normal mt-3 md:mt-6">{{ $t('A life remembered forever') }}</div>
            <div class="mt-4 md:mt-12 w-full md:w-auto">
                <PrimaryButton :lg="true" class="mr-3 w-full md:w-auto" @click="handleAddMyStoryClick">{{ $t('Add my story') }}</PrimaryButton>
                <SecondaryButton :lg="true" class="mt-3 lg:mt-0 w-full md:w-auto" @click="handleAddLovedOneStoryClick">{{ $t("Add a loved one's story") }}</SecondaryButton>
            </div>
        </div>
        <div class="flex flex-1 justify-center">
            <img class="object-contain" src="/storage/images/home_photo_2.png" alt="hero"/>
        </div>
    </div>
</template>

<style scoped>

</style>
